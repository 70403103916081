export default {
	en: 'English',
	fr: 'Français',
	de: 'Deutsch',
	it: 'Italiano',
	ca: 'Català',
	es_ES: 'Español',
	pt_BR: 'Português (Brasil)',
	pt_PT: 'Português (Portugal)',
	nl: 'Nederlandse',
	da: 'Dansk',
	sv: 'Svenska',
	nb: 'Norsk',
	fi: 'Suomi',
	pl: 'Polski',
	cz: 'Čeština',
	sk: 'Slovenský',
	hu: 'Magyar',
	ro: 'Română',
	gr: 'Ελληνικά',
	sr: 'Српски (ћирилица)',
	sr_YU: 'Srpski (latinica)',
	uk: 'Українська',
	ru: 'Русский',
	tr: 'Türkçe',
	ar: 'العربية',
	fa: 'فارسی',
	zh_CN: '简体中文',
	zh_HK: '繁體中文',
	jp: '日本語',
	id: 'Indonesia',
	vi: 'Tiếng Việt',
}

// uses basic latin characters if not included
export const subsets = {
	gr: 'greek',
	ar: 'arabic',
	fa: 'arabic',
	ru: 'cyrillic',
	uk: 'cyrillic',
	sr: 'cyrillic',
	sk: 'latin-ext',
	cz: 'latin-ext',
	pl: 'latin-ext',
	ro: 'latin-ext',
	tr: 'latin-ext',
	hu: 'latin-ext',
	vi: 'latin-ext',
	jp: 'japanese',
	zh_CN: 'chinese-simplified',
	zh_HK: 'chinese-traditional',
}
